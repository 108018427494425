import { Input } from "antd";
import React, { useRef, useState } from "react";
import { Group } from "../lib/definitions";
import { capitalizeName } from "../lib/functions";
import { Card } from "./card";
import { Header } from "./header";

interface SettingsListProps {
  selectedGroup: Group | undefined;
  items: any[];
  onCreate(name: string): void;
  onDelete(name: string): void;
  onClick(item: any): void;
}

export function SettingsGroups(props: SettingsListProps) {
  const [name, setName] = useState("");

  const inputRef = useRef(null);

  function create() {
    if (!props.selectedGroup) {
      //@ts-ignore
      inputRef.current?.blur();
    }

    if (
      props.items.find((item) =>
        props.selectedGroup
          ? capitalizeName(item) === capitalizeName(name)
          : capitalizeName(item.name) === capitalizeName(name)
      )
    ) {
      alert(
        `${
          props.selectedGroup ? "Medlemsnavn" : "Gruppenavn"
        } eksisterer allerede`
      );
      return;
    }

    props.onCreate(capitalizeName(name));

    setName("");
  }

  return (
    <div>
      <Header
        title={
          props.selectedGroup
            ? `Medlemmer i ${props.selectedGroup.name}`
            : "Grupper"
        }
      />
      <Input
        placeholder={props.selectedGroup ? "Nyt medlem..." : "Ny gruppe..."}
        value={name}
        onChange={(e) => setName(e.target.value)}
        size="large"
        style={{ borderRadius: "4px", marginBottom: "20px" }}
        onPressEnter={create}
        ref={inputRef}
      />
      {props.items.map((item) => (
        <Card
          key={props.selectedGroup ? item : item.name}
          title={
            props.selectedGroup ? item : `(${item.members.length}) ${item.name}`
          }
          onClick={() => props.onClick(item)}
          onDelete={() =>
            props.selectedGroup
              ? props.onDelete(item)
              : window.confirm("Er du sikker?") && props.onDelete(item.name)
          }
        />
      ))}
      <div style={{ height: "48px" }} />
    </div>
  );
}
