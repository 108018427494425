import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";

interface BackButtonProps {
  onClick(): void;
}

export function BackButton(props: BackButtonProps) {
  return (
    <ArrowLeftOutlined
      onClick={props.onClick}
      style={{
        fontSize: "48px",
        position: "fixed",
        margin: "24px",
        color: "gray",
      }}
    />
  );
}
