import React from "react";

interface BigButtonProps {
  text: string;
  onClick(): void;
  disabled?: boolean;
}

export function BigButton(props: BigButtonProps) {
  return (
    <div
      className="card"
      style={{
        background: "#1890ff",
        borderRadius: "30px",
        width: "70%",
        opacity: !props.disabled ? "1" : "0.4",
      }}
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
    >
      <h3
        style={{
          margin: 0,
          color: "white",
          width: "100%",
          textAlign: "center",
        }}
      >
        {props.text}
      </h3>
    </div>
  );
}
