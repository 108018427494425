import React, { useCallback, useEffect, useState } from "react";
import { shuffleArray } from "../lib/functions";
import { BackButton } from "./backButton";
import { BigButton } from "./bigButton";
import { Container } from "./container";

interface DisplayTeamsViewProps {
  members: string[];
  numberOfTeams: 0 | 2 | 3 | 4 | 5;
  onBack(): void;
}

export function DisplayTeamsView(props: DisplayTeamsViewProps) {
  const [teams, setTeams] = useState<Map<number, string[]>>(new Map());

  const makeTeams = useCallback(() => {
    if (props.numberOfTeams) {
      const newTeams: Map<number, string[]> = new Map();
      shuffleArray(props.members).forEach((member, i) =>
        newTeams.set(
          i % props.numberOfTeams,
          (newTeams.get(i % props.numberOfTeams) || []).concat(member)
        )
      );
      newTeams.forEach((members) => members.sort());
      setTeams(newTeams);
    }
  }, [props.numberOfTeams, props.members]);

  useEffect(makeTeams, [makeTeams]);

  return (
    <div>
      <BackButton onClick={props.onBack} />
      <Container>
        {Array.from(teams.keys()).map((teamNumber) => (
          <div className="card" style={{ height: "unset" }}>
            <div>
              <h2>{`Hold ${teamNumber + 1}`}</h2>
              {teams.get(teamNumber)?.map((name, i) => (
                <h3
                  style={{
                    margin:
                      teams.get(teamNumber) &&
                      i === teams.get(teamNumber)!.length - 1
                        ? 0
                        : undefined,
                  }}
                >
                  {name}
                </h3>
              ))}
            </div>
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "36px",
          }}
        >
          <BigButton text="Bland igen" onClick={makeTeams} disabled={false} />
        </div>
      </Container>
    </div>
  );
}
