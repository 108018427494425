import React from "react";
import { Group } from "../lib/definitions";
import { BackButton } from "./backButton";
import { BigButton } from "./bigButton";
import { Container } from "./container";
import { Header } from "./header";

interface TeamMakerViewProps {
  groups: Group[];
  setGroups(groups: Group[]): void;
  onBack(): void;
  onDisplayTeams(members: string[], numberOfTeams: 2 | 3 | 4 | 5): void;
}

interface TeamMakerViewProps {
  selectedGroup: Group | undefined;
  selectedMembers: string[];
  numberOfTeams: 0 | 2 | 3 | 4 | 5;
  setSelectedGroup(group: Group | undefined): void;
  setSelectedMembers(members: string[]): void;
  setNumberOfTeams(numberOfTeams: 0 | 2 | 3 | 4 | 5): void;
}

export function TeamMakerView(props: TeamMakerViewProps) {
  const numberTile = (number: 2 | 3 | 4 | 5) => (
    <div
      style={{
        background: "white",
        borderRadius: "8px",
        boxShadow:
          "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: props.numberOfTeams === number ? "1" : "0.4",
      }}
      onClick={() => props.setNumberOfTeams(number)}
    >
      <h2 style={{ margin: 0 }}>{number}</h2>
    </div>
  );

  return (
    <div>
      <BackButton
        onClick={
          !props.selectedGroup
            ? props.onBack
            : () => {
                props.setSelectedGroup(undefined);
                props.setSelectedMembers([]);
                props.setNumberOfTeams(0);
              }
        }
      />
      <Container>
        <Header
          title={props.selectedGroup ? "Vælg deltagere..." : "Vælg gruppe..."}
        />
        {props.selectedGroup ? (
          <>
            {props.selectedGroup.members.map((member) => (
              <div
                key={member}
                className="card"
                style={{
                  opacity: props.selectedMembers.includes(member) ? "1" : "0.4",
                }}
                onClick={() => {
                  if (props.selectedMembers.includes(member)) {
                    props.setSelectedMembers(
                      props.selectedMembers.filter((m) => m !== member)
                    );
                  } else {
                    props.setSelectedMembers(
                      props.selectedMembers.concat(member)
                    );
                  }
                }}
              >
                <h3 style={{ margin: 0, width: "100%" }}>{member}</h3>
              </div>
            ))}
            <Header title="Vælg antal hold..." />
            <div
              style={{
                height: "60px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                columnGap: "8px",
              }}
            >
              {numberTile(2)}
              {numberTile(3)}
              {numberTile(4)}
              {numberTile(5)}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "36px 0",
              }}
            >
              <BigButton
                text="Generer hold"
                onClick={() => {
                  if (props.numberOfTeams) {
                    props.onDisplayTeams(
                      props.selectedMembers,
                      props.numberOfTeams
                    );
                  }
                }}
                disabled={
                  !props.numberOfTeams || props.selectedMembers.length === 0
                }
              />
            </div>
          </>
        ) : (
          props.groups.map((group) => (
            <div
              key={group.name}
              className="card"
              onClick={() => props.setSelectedGroup(group)}
            >
              <h3 style={{ margin: 0, width: "100%" }}>{group.name}</h3>
            </div>
          ))
        )}
      </Container>
    </div>
  );
}
