import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

interface CardProps {
  title: string;
  onClick(): void;
  onDelete(): void;
}

export function Card(props: CardProps) {
  return (
    <div onClick={props.onClick} className="card">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h3 className="card__title">
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.title}
          </div>
          <DeleteOutlined
            style={{ fontSize: "24px", color: "#ff6262" }}
            onClick={(e) => {
              props.onDelete();
              e.stopPropagation();
            }}
          />
        </h3>
      </div>
    </div>
  );
}
