import React from "react";

interface ContainerProps {
  children?: React.ReactNode;
}

export function Container(props: ContainerProps) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ paddingTop: "80px", width: "60%" }}>{props.children}</div>
    </div>
  );
}
