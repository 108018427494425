function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalizeName(name: string) {
  return name.split(" ").map(capitalize).join(" ");
}

export function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
