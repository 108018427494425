import React, { useEffect, useState } from "react";
import { Group } from "../lib/definitions";
import { BackButton } from "./backButton";
import { Container } from "./container";
import * as localForage from "localforage";
import { SettingsGroups } from "./settingsList";

interface SettingsViewProps {
  groups: Group[];
  setGroups(groups: Group[]): void;
  refreshGroups(): void;
  onBack(): void;
}

export function SettingsView(props: SettingsViewProps) {
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  useEffect(() => {
    if (selectedGroup) {
      setSelectedGroup(
        props.groups.find((group) => group.name === selectedGroup.name)
      );
    }
  }, [props.groups, selectedGroup]);

  async function createGroup(groupName: string) {
    const newGroups = props.groups
      .concat({ name: groupName, members: [] })
      .sort((a, b) => a.name.localeCompare(b.name));
    await localForage.setItem("groups", newGroups);

    props.refreshGroups();
  }

  async function deleteGroup(groupName: string) {
    const newGroups = props.groups.filter((group) => group.name !== groupName);
    await localForage.setItem("groups", newGroups);

    props.refreshGroups();
  }

  async function addMember(memberName: string) {
    const group = props.groups.find((g) => g.name === selectedGroup?.name);
    const newGroup = {
      ...group,
      members: group?.members.concat(memberName).sort(),
    };
    const newGroups = props.groups.map((g) => {
      if (g.name !== selectedGroup?.name) {
        return g;
      } else {
        return newGroup;
      }
    });
    await localForage.setItem("groups", newGroups);

    props.refreshGroups();
  }

  async function deleteMember(memberName: string) {
    const group = props.groups.find((g) => g.name === selectedGroup?.name);
    const newGroup = {
      ...group,
      members: group?.members.filter((name) => name !== memberName),
    };
    const newGroups = props.groups.map((g) => {
      if (g.name !== selectedGroup?.name) {
        return g;
      } else {
        return newGroup;
      }
    });
    await localForage.setItem("groups", newGroups);

    props.refreshGroups();
  }

  return (
    <div>
      <BackButton
        onClick={
          selectedGroup ? () => setSelectedGroup(undefined) : props.onBack
        }
      />
      <Container>
        <SettingsGroups
          selectedGroup={selectedGroup}
          items={selectedGroup ? selectedGroup.members : props.groups}
          onCreate={selectedGroup ? addMember : createGroup}
          onDelete={selectedGroup ? deleteMember : deleteGroup}
          onClick={selectedGroup ? () => {} : setSelectedGroup}
        />
      </Container>
    </div>
  );
}
