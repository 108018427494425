import React from "react";

interface HeaderProps {
  title: string;
}

export function Header(props: HeaderProps) {
  return (
    <div>
      <h2 style={{ margin: 0 }}>{props.title}</h2>
      <div
        style={{
          height: "1px",
          background: "gray",
          margin: "8px 0 16px 0",
        }}
      />
    </div>
  );
}
