import { OrderedListOutlined, SettingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { SettingsView } from "./components/settingsView";
import { TeamMakerView } from "./components/teamMakeView";
import { Group } from "./lib/definitions";
import * as localForage from "localforage";
import { DisplayTeamsView } from "./components/displayTeamsView";

function App() {
  const [currentView, setCurrentView] = useState<0 | 1 | 2 | 3>(0);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [members, setMembers] = useState<string[]>([]);
  const [numberOfTeams, setNumberOfTeams] = useState<0 | 2 | 3 | 4 | 5>(0);

  useEffect(() => {
    refreshGroups();
  }, []);

  async function refreshGroups() {
    const newGroups: Group[] = (await localForage.getItem("groups")) || [];
    setGroups(newGroups);
  }

  return (
    <div style={{ height: "100%" }}>
      {currentView === 0 ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setCurrentView(1)}
            className="select-mode-card"
            style={{ marginTop: "-24px" }}
          >
            <OrderedListOutlined style={{ marginRight: "12px" }} />
            Lav hold
          </div>
          <div onClick={() => setCurrentView(2)} className="select-mode-card">
            <SettingOutlined style={{ marginRight: "12px" }} />
            Grupper
          </div>
        </div>
      ) : (
        <>
          {currentView === 1 && (
            <TeamMakerView
              onBack={() => setCurrentView(0)}
              groups={groups}
              setGroups={setGroups}
              onDisplayTeams={(members, numberOfTeams) => {
                setCurrentView(3);
                setMembers(members);
                setNumberOfTeams(numberOfTeams);
              }}
              selectedMembers={members}
              setSelectedMembers={setMembers}
              numberOfTeams={numberOfTeams}
              setNumberOfTeams={setNumberOfTeams}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          )}
          {currentView === 2 && (
            <SettingsView
              onBack={() => setCurrentView(0)}
              groups={groups}
              setGroups={setGroups}
              refreshGroups={refreshGroups}
            />
          )}
          {currentView === 3 && (
            <DisplayTeamsView
              onBack={() => setCurrentView(1)}
              members={members}
              numberOfTeams={numberOfTeams}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
